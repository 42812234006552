





























































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {GroupPlan, GroupPlanItem, User} from "@/api";
import groupPlanService from "@/service/group-plan.service";
import moment from "moment";

const Auth = namespace("Auth");

@Component
export default class GroupPlanTable extends Vue {
  @Auth.State("user")
  private currentUser!: User;

  groupPlans: GroupPlan[] = [];
  loading = false;


  get headers(): any {
    return [
      {
        text: this.$t('cards.group.header.id') as string,
        align: 'start',
        value: 'id',
      },
      {text: this.$t('cards.groupPlan.header.groupName') as string, value: 'group.name'},
      {text: this.$t('cards.groupPlan.header.description') as string, value: 'description'},
      {text: this.$t('cards.groupPlan.header.maxCount') as string, value: 'maxCount'},
      {text: this.$t('cards.groupPlan.header.active') as string, value: 'active'},
    ]
  }

  private loadGroupPlans() {
    this.loading = true;
    groupPlanService.getAllGroups()
        .then(resp => this.groupPlans = resp.data)
        .catch(error => {
          console.log(error);
        }).finally(() => {
      this.loading = false;
    })
  }

  chipText(item: GroupPlanItem): string {
    if (item.dayOfWeek) {
      moment.locale('ru');
      const day = moment(item.dayOfWeek, 'e').startOf('week').isoWeekday(item.dayOfWeek).format('dddd');
      return day + ' ' + item.startTime! + ' - ' + item.endTime! + ' ' + (item.description ? item.description! : '');
    }
    return '';
  }

  mounted() {
    this.loadGroupPlans();
  }
}
