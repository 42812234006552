import {groupPlanApi} from "@/api/api.instance";
import {AxiosPromise} from "axios";
import {GroupPlan} from "@/api";

export class GroupPlanService {
    getAllGroups(): AxiosPromise<Array<GroupPlan>> {
        return groupPlanApi.getAllGroupPlans();
    }
}

export default new GroupPlanService();
