































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {Group, User} from "@/api";
import groupService from "@/service/group.service";

const Auth = namespace("Auth");

@Component
export default class GroupTable extends Vue {
  @Auth.State("user")
  private currentUser!: User;

  groups: Group[] = [];
  loading = false;


  get headers(): any {
    return [
      {
        text: this.$t('cards.group.header.id') as string,
        align: 'start',
        value: 'id',
      },
      {text: this.$t('cards.group.header.code') as string, value: 'code'},
      {text: this.$t('cards.group.header.name') as string, value: 'name'},
      {text: this.$t('cards.group.header.trainer') as string, value: 'trainer'},
      {text: this.$t('cards.group.header.location') as string, value: 'location.name'},
      {text: this.$t('cards.group.header.description') as string, value: 'description'},
      {text: this.$t('cards.group.header.active') as string, value: 'active'},
    ]
  }


  private loadGroups() {
    this.loading = true;
    groupService.getAllGroups()
        .then(resp => this.groups = resp.data)
        .catch(error => {
          console.log(error);
        }).finally(() => {
      this.loading = false;
    })
  }

  mounted() {
    this.loadGroups();
  }

}
